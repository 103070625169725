import { AxiosResponse } from "axios";
import Cookies from "js-cookie";
import { sha256 } from "js-sha256";
import { BO_CORE_DEFAULT_VALUE, getAxiosInstances, getHostnameWithoutTenantSubdomain, getLocaleNameByTag, isCancelledError, toastError } from "../AppUtils";
import store from "../../store";
import { fetchAuthUser, setApplicationLocale, setAuthenticatedUser } from "../../reducers/userReducer";
import { Routes, PublicRoutes, UserRoles } from "../appEnums/AppEnums";
import { LOCAL_STORAGE_ITEM_LOCALE } from "../../config/app.config";
import AxiosActions from "../../config/axios/AxiosActions";
import { durationToExactDate, getLocales } from "../DateTimeUtils";
import { setSimpleSpinner } from "../../reducers/spinnerReducer";

export interface UserPreference {
    locale: { value: string; label: string };
}

// export const AVAILABLE_USER_ROLES_IN_ACTIVE_MANAGEMENT_MODE = [UserRoles.Employee, UserRoles.Manager, UserRoles.SA];

const rootHostName = getHostnameWithoutTenantSubdomain(false);

let authUser: any = null;

const SESSION_STORAGE_ACCESS_TOKEN = "access_token";

const SESSION_STORAGE_CUSTOMER_SERVICE_ACCESS_TOKEN = "customer_service_access_token";

const SESSION_STORAGE_REFRESH_TOKEN = "refresh_token";

const SESSION_STORAGE_CUSTOMER_SERVICE_REFRESH_TOKEN = "customer_service_refresh_token";

const SESSION_STORAGE_CODE_VERIFIER = "code_verifier";

export function getAuthUser() {
    return authUser;
}

export function setAuthUser(value) {
    authUser = value;
}

export const getAccessToken = () => {
    return Cookies.get(SESSION_STORAGE_ACCESS_TOKEN);
};
export const isRefreshTokenPresent = (): boolean => {
    return !!Cookies.get(SESSION_STORAGE_REFRESH_TOKEN);
};
export const isAccessTokenPresent = (): boolean => {
    return !!Cookies.get(SESSION_STORAGE_ACCESS_TOKEN);
};
export const getRefreshToken = () => {
    return Cookies.get(SESSION_STORAGE_REFRESH_TOKEN);
};
export const isAssumingIdentity = () => {
    return !!Cookies.get(SESSION_STORAGE_CUSTOMER_SERVICE_ACCESS_TOKEN);
};
export const mySelfAuthHelper = (showSpinner?: boolean, signal?: AbortSignal): Promise<AxiosResponse> => {
    return AxiosActions.get(getAxiosInstances().V2, `user/myself`, { signal }, showSpinner === true);
};
export const mutateMySelfResponse = (response: AxiosResponse) => {
    const mutatedResponse = response;

    if (mutatedResponse.data.customerSupport) {
        mutatedResponse.data.roles.push(UserRoles.CS);
    }

    return mutatedResponse;
};
export const updateUserLocaleAuthHelper = (localeTag: string): Promise<AxiosResponse> => {
    return AxiosActions.post(getAxiosInstances().V2, `user/locale?locale=${localeTag}`, {}, {}, false);
};
export const clearTokenCookies = () => {
    Cookies.remove(SESSION_STORAGE_ACCESS_TOKEN, { domain: rootHostName });
    Cookies.remove(SESSION_STORAGE_REFRESH_TOKEN, { domain: rootHostName });
};
export const clearAssumedTokenCookies = () => {
    Cookies.remove(SESSION_STORAGE_CUSTOMER_SERVICE_ACCESS_TOKEN, { domain: rootHostName });
    Cookies.remove(SESSION_STORAGE_CUSTOMER_SERVICE_REFRESH_TOKEN, { domain: rootHostName });
};
export const processMySelfRequestError = (e) => {
    if (isCancelledError(e)) {
        return;
    }

    store.dispatch(setSimpleSpinner(false));
    store.dispatch(fetchAuthUser(false));
    clearTokenCookies();
    clearAssumedTokenCookies();

    window.location.href = `${PublicRoutes.INIT_AUTH}`;
};
export const setTokenCookies = (accessToken: string, refreshToken: string, accessTokenExpiry: number, _refreshTokenExpiry: number) => {
    // debug
    // console.log("set: accessToken");
    // console.log("set: refreshToken");
    // setTimeout(() => console.log("expired: accessToken"), accessTokenExpiry * 1000);
    // setTimeout(() => console.log("expired: refreshToken"), accessTokenExpiry * 2 * 1000);

    Cookies.set(SESSION_STORAGE_ACCESS_TOKEN, accessToken, {
        expires: durationToExactDate(accessTokenExpiry, "second"),
        sameSite: "Strict",
        secure: true,
        domain: rootHostName,
    });
    Cookies.set(SESSION_STORAGE_REFRESH_TOKEN, refreshToken || "", {
        expires: durationToExactDate(accessTokenExpiry * 2, "second"), // todo when be starts returning expiry
        sameSite: "Strict",
        secure: true,
        domain: rootHostName,
    });
};
export const processMySelfRequest = (response: AxiosResponse) => {
    const mutatedResponse = mutateMySelfResponse(response);
    setAuthUser(mutatedResponse.data);
    store.dispatch(setAuthenticatedUser(getAuthUser()));
    return mutatedResponse;
};
export const logoutHelper = () => {
    return AxiosActions.postErrorsAreThrown(getAxiosInstances().AUTH, "user/logout", {}, { withCredentials: true }, false);
};
export const setAssumeIdentityTokenCookies = (accessToken: string, refreshToken: string, accessTokenExpiry: number, _refreshTokenExpiry: number) => {
    const originalAccessToken = getAccessToken() as string;
    const originalRefreshToken = getRefreshToken() as string;

    Cookies.set(SESSION_STORAGE_CUSTOMER_SERVICE_ACCESS_TOKEN, originalAccessToken, {
        expires: durationToExactDate(accessTokenExpiry, "second"),
        domain: rootHostName,
    });
    Cookies.set(SESSION_STORAGE_CUSTOMER_SERVICE_REFRESH_TOKEN, originalRefreshToken, {
        expires: durationToExactDate(accessTokenExpiry * 2, "second"),
        domain: rootHostName,
    }); // todo when be starts returning expiry

    setTokenCookies(accessToken, refreshToken, accessTokenExpiry, _refreshTokenExpiry);
    mySelfAuthHelper(true).then(processMySelfRequest).catch(processMySelfRequestError);
};
export const restoreIdentity = async () => {
    const accessTokenExpiry = 300;
    const refreshTokenExpiry = accessTokenExpiry * 2;

    const originalAccessToken = Cookies.get(SESSION_STORAGE_CUSTOMER_SERVICE_ACCESS_TOKEN) as string;
    const originalRefreshToken = Cookies.get(SESSION_STORAGE_CUSTOMER_SERVICE_REFRESH_TOKEN) as string;

    await logoutHelper();
    clearTokenCookies();
    setTokenCookies(originalAccessToken, originalRefreshToken, accessTokenExpiry, refreshTokenExpiry);
    clearAssumedTokenCookies();
    mySelfAuthHelper(true)
        .then(processMySelfRequest)
        .then((response) => {
            window.location.href = `${Routes.SYSTEM_SUPPORT}`;
            return response;
        })
        .catch(processMySelfRequestError);
};
export const setCodeVerifierCookies = (codeVerifier: string, codeVerifierExpiry: number) => {
    Cookies.set(SESSION_STORAGE_CODE_VERIFIER, codeVerifier, { expires: durationToExactDate(codeVerifierExpiry, "second") });
};
export const retrievePreferredLocale = (myselfLocale: any) => {
    if (myselfLocale && myselfLocale !== BO_CORE_DEFAULT_VALUE) {
        return myselfLocale;
    }
    const locale: { label: string; value: string } = JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_ITEM_LOCALE) as string);
    return locale ? locale.value : getLocales();
};
export const getCodeVerifierCookies = () => {
    return Cookies.get(SESSION_STORAGE_CODE_VERIFIER);
};
export const removeCodeVerifierCookies = () => {
    Cookies.remove(SESSION_STORAGE_CODE_VERIFIER);
};
export const objectToEncodedRequestBody = (object: any): string => {
    const formBody: string[] = [];

    Object.keys(object).forEach((property: string) => {
        const encodedKey = encodeURIComponent(property);
        const encodedValue = encodeURIComponent(object[property]);
        formBody.push(`${encodedKey}=${encodedValue}`);
    });

    return formBody.join("&");
};
export const updateUserLocale = (myselfLocale: any, currentUserPreference: UserPreference) => {
    const preferredLocale = retrievePreferredLocale(myselfLocale);
    if (preferredLocale && preferredLocale !== currentUserPreference.locale.value) {
        store.dispatch(setApplicationLocale({ value: preferredLocale, label: getLocaleNameByTag(preferredLocale) }));
    }
    const saveUserPreferredLocale = preferredLocale !== myselfLocale;
    if (saveUserPreferredLocale) {
        updateUserLocaleAuthHelper(preferredLocale).catch(() => {
            toastError("Failed saving user preferred location.");
        });
    }
};
export const updateUserPreferences = (myselfResponse: any, currentUserPreference: UserPreference) => {
    updateUserLocale(myselfResponse.locale, currentUserPreference);
};
export const isUserProctor = (userData: any): boolean => {
    return userData.roles.includes(UserRoles.Proctor || UserRoles.SA) || getAuthUser().roles.includes(UserRoles.SA);
};
export const isUserOnlyPatient = (userData: any): boolean => {
    if (isUserProctor(userData)) {
        return false;
    }
    return getAuthUser().roles.includes(UserRoles.Patient);
};
export const mySelfRequest = (userPreference: UserPreference, signal?: AbortSignal) => {
    return mySelfAuthHelper(true, signal)
        .then((response) => {
            updateUserPreferences(response.data, userPreference);
            return response;
        })
        .then(processMySelfRequest)
        .catch(processMySelfRequestError);
};
export const serverLogOut = () => {
    return logoutHelper().then(() => {
        clearTokenCookies();

        if (isAssumingIdentity()) {
            clearAssumedTokenCookies();
        }

        window.location.href = `${PublicRoutes.LOGIN}`;
    });
};

const base64URLEncode = (arrayBuffer) => {
    const binaryString = String.fromCharCode(...new Uint8Array(arrayBuffer));
    return btoa(binaryString).replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
};

export const generateCodeVerifier = () => {
    const array = new Uint8Array(32); // 32 bytes = 256 bits
    crypto.getRandomValues(array); // Fill with cryptographically secure random values
    return btoa(String.fromCharCode(...array))
        .replace(/\+/g, "-")
        .replace(/\//g, "_")
        .replace(/=+$/, ""); // Base64 URL encode
};

// Generate a code_challenge from the code_verifier
export const generateCodeChallenge = (codeVerifier) => {
    // Hash the UTF-8 encoded code_verifier
    const hashBuffer = sha256.arrayBuffer(codeVerifier);
    // Base64 URL-encode the hash
    return base64URLEncode(hashBuffer);
};
