export const DatesFormat = {
    defaultFormat: "YYYY-MM-DD",
    defaultDateTimeFormat: "YYYY-MM-DD HH:mm A Z",
    newDefaultFormat: "MM-DD-YYYY",
    fullNewDefaultFormat: "HH:mm, MM/DD/YYYY",
    defaultUSFormat: "MM/DD/YYYY",
    defaultUSFormatDateTime: "MM/DD/YYYY hh:mm A",
    defaultUSDateTimeFormat: "MM/DD/YYYY hh:mm A Z",
    // inputFormat: "MM-dd-yyyy",
} as const;
