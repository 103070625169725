import { AxiosRequestConfig, AxiosResponse } from "axios";
import { mySelfAuthHelper } from "../../../../utils/AuthUtils/AuthUtils";
import AxiosActions from "../../AxiosActions";
import { getAxiosInstances } from "../../../../utils/AppUtils";
import { TListApiResponse } from "../commonTypes";
import { ImportCustomDataQueryKeys, ImportExportTypeKeys } from "../../../../views/privatePages/ImportAndExport/ImportAndExportTypes";

export const mySelf = (showSpinner?: boolean): Promise<AxiosResponse> => {
    return mySelfAuthHelper(showSpinner);
};

/**
 * @returns instead of MailingAddress API maps PracticeAddress in the same fields name
 */
export const getNpiList = (entityTypeCode: number, limit: number, page: number, searchText: string, spinner: boolean, config): Promise<AxiosResponse> => {
    return AxiosActions.get(
        getAxiosInstances().V2,
        `public/npi/list`, // ?entityTypeCode=${entityTypeCode}&limit=${limit}&page=${page}&searchText=${searchText}
        {
            withCredentials: false,
            params: {
                entityTypeCode,
                limit,
                page,
                searchText,
            },
            ...config,
        },
        spinner,
        false,
    );
};

export const getTemplateImportFile = (key: ImportExportTypeKeys, config: AxiosRequestConfig = {}, spinner: boolean = true): Promise<AxiosResponse<any>> => {
    return AxiosActions.get(getAxiosInstances().V2, `/file/template/${key}/download`, config, spinner, false);
};

export const exportData = <T>(dto: T, queryKey: string, radioFilter: string): Promise<AxiosResponse> => {
    return AxiosActions.post(getAxiosInstances().V2, `/data/query-key/${queryKey}/export?${radioFilter}`, dto, { responseType: "blob" }, true);
};

export const importData = <T>(queryKey: string, formData: T) => {
    return AxiosActions.post(getAxiosInstances().V2, `/data/query-key/${queryKey}/import/start`, formData, {}, true);
};

export const importCustomData = <T>(queryKey: ImportCustomDataQueryKeys, formData: T) => {
    return AxiosActions.post(getAxiosInstances().V2, `/data/query-key/${queryKey}/custom-import/start`, formData, {}, true);
};

export const getAssumedIdentity = (id: number, config = {}): Promise<AxiosResponse> => {
    return AxiosActions.get(getAxiosInstances().V2, `/token/assume-identity/${id}`, { ...config, withCredentials: true }, true);
};

export const getIfNpiIsRegisteredAsPersonalOrPractice = (npiId: number, spinner: boolean): Promise<AxiosResponse> => {
    return AxiosActions.get(getAxiosInstances().V2, `/health/npi/${npiId}/is-registered`, {}, spinner);
};

export const verifyEmail = (userId: number, code: string): Promise<AxiosResponse> => {
    return AxiosActions.post(getAxiosInstances().V2, `public/verify-email/${userId}/${code}`, {}, { withCredentials: false }, false);
};
export const sendAllRequests = async (requests: [], showSpinner: boolean): Promise<AxiosResponse[]> => {
    return AxiosActions.all(requests, showSpinner);
};

export const getAllCountries = (config?: AxiosRequestConfig, spinner: boolean = true): Promise<AxiosResponse> => {
    return AxiosActions.get(getAxiosInstances().V2, `public/countries`, { withCredentials: false, ...config }, spinner);
};

export const deleteInboundShipment = (id: number, config: any = {}, spinner: boolean = false): Promise<AxiosResponse> => {
    return AxiosActions.delete(getAxiosInstances().V2, `/shipment/inbound/${id}`, config, spinner);
};

export const getServerCurrentTime = (spinner: boolean = false, config: AxiosRequestConfig<any> = {}) => {
    return AxiosActions.get(getAxiosInstances().V2, "public/timestamp", { ...config, withCredentials: false }, spinner);
};

export const getUrlFromShortCode = (spinner: boolean = false, config: AxiosRequestConfig<any> = {}): Promise<AxiosResponse<string>> => {
    return AxiosActions.get(getAxiosInstances().V2, "/public/url-mapping", { ...config, withCredentials: false }, spinner);
};

enum DocumentType {
    TERMS_AND_CONDITIONS = "Terms and Conditions",
    BAA_STANDARD = "BAA Organization Standard",
}

export interface Document {
    name: string;
    id: number;
    type: `${DocumentType}`;
    content: {
        name: string;
        id: number;
        currentVersionId: number;
        publicUrl: string;
    };
    state: {
        accepted: boolean;
        actionPerformedAt: string; // the action may be an acceptance or a decline
        byPerson: {
            id: number;
            birthDate: string;
            email: string;
            firstName: string;
            lastName: string;
            phoneNumber: string;
            phoneNumberRegion: string;
            userId: number;
        };
    };
}

export const getDocumentsToSign = (type: `${DocumentType}`, config: AxiosRequestConfig = {}, spinner: boolean = true): Promise<AxiosResponse<Document[]>> => {
    return AxiosActions.get(getAxiosInstances().V2, `/agreement/${type}`, config, spinner);
};

export const signDocuments = (
    type: `${DocumentType}`,
    // Agreement IDs
    dto: { ids: number[] },
    config: AxiosRequestConfig = {},
    spinner: boolean = true,
): Promise<AxiosResponse<TListApiResponse<Document>>> => {
    return AxiosActions.put(getAxiosInstances().V2, `/agreement/${type}/accept`, dto, config, spinner);
};
