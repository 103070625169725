import { GridActiveConfigs, GridConfigCache } from "../types";

export const defaultCache: GridConfigCache = {
    campaign: { map: {}, activeId: undefined },
    dryLabTest: { map: {}, activeId: undefined },
    labRequisition: { map: {}, activeId: undefined },
    labResults: { map: {}, activeId: undefined },
    healthcarePatients: { map: {}, activeId: undefined },
    labPatients: { map: {}, activeId: undefined },
    gtmOrders: { map: {}, activeId: undefined },
    healthProviderOrders: { map: {}, activeId: undefined },
    masterOrderComponents: { map: {}, activeId: undefined },
    // healthcareTestOrders: { map: {}, activeId: undefined }, // the grid associated with this identifier is no longer used
    // labTestOrders: { map: {}, activeId: undefined }, // the grid associated with this identifier is no longer used
    personalResults: { map: {}, activeId: undefined },
    campaignDetailsJourneys: { map: {}, activeId: undefined },
    campaignDetailsJourneysTasks: { map: {}, activeId: undefined },
    campaignDetailsJourneysTransferOfCare: { map: {}, activeId: undefined },
    videoSessions: { map: {}, activeId: undefined },
    personalInformationProctoredTests: { map: {}, activeId: undefined },
    samplingKit: { map: {}, activeId: undefined },
    supplyProduct: { map: {}, activeId: undefined },
    supplyFulfillmentOrders: { map: {}, activeId: undefined },
    bomForm: { map: {}, activeId: undefined },
    systemSupport: { map: {}, activeId: undefined },
    apiKeys: { map: {}, activeId: undefined },
    accounts: { map: {}, activeId: undefined },
    academicTests: { map: {}, activeId: undefined },
    askAtOrderEntry: { map: {}, activeId: undefined },
    availableLocations: { map: {}, activeId: undefined },
    icd10CodesCommon: { map: {}, activeId: undefined },
    icd10CodesLessCommon: { map: {}, activeId: undefined },
    results: { map: {}, activeId: undefined },
    testOffered: { map: {}, activeId: undefined },
    testProduct: { map: {}, activeId: undefined },
    patientTestOrders: { map: {}, activeId: undefined },
    testOfferedsForOrdering: { map: {}, activeId: undefined },
    importLog: { map: {}, activeId: undefined },
    exportLog: { map: {}, activeId: undefined },
    campaignPatients: { map: {}, activeId: undefined },
    campaignLogs: { map: {}, activeId: undefined },
    filterViews: { map: {}, activeId: undefined },
    filterViewEvaluatePreview: { map: {}, activeId: undefined },
};

export const initialActiveConfigs: Partial<GridActiveConfigs> = {
    campaign: undefined,
    dryLabTest: undefined,
    labRequisition: undefined,
    labResults: undefined,
    gtmOrders: undefined,
    healthProviderOrders: undefined,
    masterOrderComponents: undefined,
    // healthcareTestOrders: undefined, // the grid associated with this identifier is no longer used
    // labTestOrders: undefined, // the grid associated with this identifier is no longer used
    personalResults: undefined,
    videoSessions: undefined,
    personalInformationProctoredTests: undefined,
    samplingKit: undefined,
    supplyProduct: undefined,
    supplyFulfillmentOrders: undefined,
    bomForm: undefined,
    systemSupport: undefined,
    apiKeys: undefined,
    accounts: undefined,
    academicTests: undefined,
    askAtOrderEntry: undefined,
    availableLocations: undefined,
    icd10CodesCommon: undefined,
    icd10CodesLessCommon: undefined,
    results: undefined,
    testOffered: undefined,
    testProduct: undefined,
    importLog: undefined,
    exportLog: undefined,
    campaignPatients: undefined,
    campaignLogs: undefined,
};
